import http from '@/utils/http'

// 加入收藏
export function add_collect(data) {
    return http({
        url: '/u-base/ac-add_collect',
        method: 'get',
        params: data
    })
}

// 取消收藏
export function cancel_collect(data) {
    return http({
        url: '/u-base/ac-cancel_collect',
        method: 'get',
        params: data
    })
}

// 收藏列表
export function get_collect_list(data) {
    return http({
        url: '/u-base/ac-get_collect_list',
        method: 'get',
        params: data
    })
}