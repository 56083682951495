<template>
  <div id="collection" class="scrollbar-class">
    <h3>我的收藏</h3>
    <table class="table" v-loading.lock="loading">
      <thead>
        <tr>
          <td width="600">企业名称</td>
          <td>推广监测</td>
          <td>
            <div class="icon" @click="sortFn">
              收藏时间
              <div class="icon-box">
                <img
                  class="icon-bottom"
                  v-if="sort == 'desc'"
                  src="./../../../assets/img/collection/icon_bottom_hot.png"
                  alt=""
                />
                <img
                  class="icon-bottom"
                  v-if="sort == '' || sort == 'asc'"
                  src="./../../../assets/img/collection/icon_bottom.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == 'asc'"
                  src="./../../../assets/img/collection/icon_top_hot.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == '' || sort == 'desc'"
                  src="./../../../assets/img/collection/icon_top.png"
                  alt=""
                />
              </div>
            </div>
          </td>
          <td width="150">操作</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, index) in data" :key="index">
          <td>
            <a
              href="javascript:;"
              class="table-company text-ellipsis click-a"
              :title="el.company_name"
              @click="detailFn(el.company_name_digest)"
              >{{ el.company_name }}</a
            >
          </td>
          <td>{{ el.monitor ? "监测中" : "未监测" }}</td>
          <td>{{ el.mtime.slice(0, 10) }}</td>
          <td>
            <div class="table-btn">
              <a
                href="javascript:;"
                class="table-btn-detail"
                @click="detailFn(el.company_name_digest)"
                >查看详情</a
              >
              <a
                href="javascript:;"
                class="table-btn-cancle"
                @click="cancleFn(el.company_name_digest)"
                >取消收藏</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <NoData title="暂无收藏" v-if="!loading && data.length == 0" />
    <Page
      :config="config"
      @pageChange="pageChange"
      @limitChange="limitChange"
      v-if="config.total > config.limit && firstLoad"
    />
  </div>
</template>

<script>
import { get_collect_list, cancel_collect } from "@/api/collect";
import PinganAna from "pingansec-vue-ana";
export default {
  data() {
    return {
      config: {
        total: 0,
        count: 5,
        sizes: [10, 20, 30, 40, 50],
        page: 1,
        limit: 10,
      },
      sort: "desc",
      data: [],
      loading: true,
      firstLoad: false,
    };
  },
  created() {
    PinganAna.fire(3098)
    this.init();
  },
  methods: {
    pageChange(val) {
      this.init();
    },
    limitChange(val) {
      this.config.page = 1;
      this.config.limit = val;
      this.init();
    },
    init() {
      this.loading = true;
      get_collect_list({
        sort: this.sort,
        page: this.config.page,
        limit: this.config.limit,
      }).then((res) => {
        let { result_code, message, data } = res.data;
        if (result_code == 0) {
          this.loading = false;
          this.firstLoad = true;
          this.data = data.rows;
          this.config.total = Number(data.cnt);
        } else {
          this.alert(message, "error");
        }
      });
    },
    detailFn(digest) {
      window.open("/box/companyDetails?digest=" + digest);
      // this.$router.push({
      //   path: "/box/companyDetails?digest=" + digest,
      // });
    },
    cancleFn(digest) {
      cancel_collect({
        digest,
      }).then((res) => {
        let { result_code, message, data } = res.data;
        if (result_code == 0) {
          this.init();
          this.alert("取消收藏成功", "success");
        } else {
          this.alert("取消收藏失败，请重试", "error");
        }
        this.init();
      });
    },
    sortFn() {
      if (this.sort == "desc") {
        this.sort = "asc";
      } else if (this.sort == "asc") {
        this.sort = "";
      } else {
        this.sort = "desc";
      }
      this.init();
    },
    // message提示框稍微包一下
    alert(txt, type) {
      this.$message.closeAll();
      this.$message({
        message: txt,
        type: type,
      });
    },
  },
};
</script>
<style scoped lang="scss" src="@/views/box/collection/index.scss"></style>
